.Die-row {
  display: flex;
  margin-bottom: 1vh;
}

.Row-text-field {
  margin-right: 1vh !important;
}

.Count-text-field {
  min-width: 8vh !important;
  max-width: 8vh !important;
}

.Additional-bonus-text-field {
  min-width: 7vh;
  max-width: 7vh;
}

.Faces-span {
  min-width: 8vh;
  margin-right: 1vh;
  max-width: 8vh;
  padding-top: 2vh;
}

.Roll-button {
  min-width: 9vh !important;
  max-width: 9vh;
  width: 9vh;
}

html,
body,
.App-root {
  height: 100%;
  width: 100%;
}

.App {
  height: 100%;
  text-align: center;
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-body {
  display: flex;
  height: calc(100% - 5vh);
  width: 100%;
}

.Dice-panel {
  height: calc(100% - 10vh);
  padding: 5vh;
  min-width: 41vh;
  max-width: 41vh;
  border-right: 1px solid;
  border-color: black;
}

.Temp-border {
  border: 1px solid;
  border-color: black;
}

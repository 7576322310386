.Results-panel {
  display: block;
  height: calc(100% - 10vh);
  overflow-y: auto;
  padding: 5vh;
  width: calc(100% - 50vh);
}

.Results-clear-button-container {
  display: flex;
}

.Results-clear-button-offset {
  width: 100%;
}

.Results-row {
  border-bottom: 1px solid;
  border-color: black;
  display: flex;
  margin-bottom: 1vh;
  padding-bottom: 1vh;
}

.Results-timestamp {
  margin-top: 1vh;
  text-align: left;
  width: 20vh;
}

.Results-data {
  margin-top: 1vh;
  width: 100%;
}

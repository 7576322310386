.Header-row {
  display: flex;
  margin-bottom: 1vh;
}

.Die-bonus-header {
  margin-left: 18vh;
}

.Total-bonus-header {
  margin-left: 1vh;
}

.Header-span {
  padding-top: 2vh;
}

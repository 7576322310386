.Custom-row {
  display: flex;
  margin-bottom: 1vh;
}

.Faces-text-field {
  min-width: 7vh !important;
  max-width: 7vh;
}

.D-span {
  min-width: 1vh;
  margin-right: 0vh;
  max-width: 1vh;
  padding-top: 2vh;
}
